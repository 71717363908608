<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card v-if="broadcast">
      <v-card-title>
        <span class="text-h5">Update Broadcast</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="broadcast.name"
                label="Name"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <tags ref="tags" :propsTags="broadcast.tags" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="broadcast.actual_links"
                label="Actual links"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="can_change_user">
              <v-autocomplete
                v-model="user"
                :items="users_list"
                :loading="isLoadingUser"
                :search-input.sync="searchUser"
                @focus="() => onLoadUsers()"
                item-text="display_name"
                label="User"
                placeholder="Search"
                return-object
                no-filter
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          :loading="is_loading"
          text
          @click="isOpen = false"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          :loading="is_loading"
          text
          @click="updateBroadcastEvent"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tags from "@/components/Tags";
import _ from "lodash";
import { updateBroadcast_v2 } from "@/store/modules/broadcasts/api";
import { requestStatus, Permissions } from "@/services/services";
import * as BROADCASTS_TYPES from "@/store/modules/broadcasts/types";
import { mapActions, mapState } from "vuex";

export default {
  name: "CreateBroadcast",
  components: { Tags },
  data: () => ({
    isOpen: false,
    broadcast: null,
    is_loading: false,
    searchUser: "",
    isLoadingUser: false,
    user: null,
  }),
  mounted() {
    window.getApp.$on("OPEN_BROADCAST_UPDATE_DIALOG", (item) => {
      this.broadcast = JSON.parse(JSON.stringify(item));
      this.user = {
        display_name: this.broadcast.user.username,
        id: this.broadcast.user.id,
      };
      this.isOpen = true;
    });
  },
  watch: {
    isOpen() {
      if (!this.isOpen) {
        this.broadcast = null;
        this.user = null;
        this.searchUser = "";
      }
    },
    searchUser(newValue) {
      if (!this.user || newValue !== this.user.display_name) {
        this.onWaitChangeUser();
      }
    },
  },
  computed: {
    ...mapState({
      users: (state) => state.broadcasts.users,
      profile: (state) => state.auth.profile,
    }),
    can_change_user: function () {
      return !!Permissions[this.profile.role]["can_change_broadcast_user"];
    },
    users_list: function () {
      let results = [...this.users];
      if (this.broadcast.user) {
        results.push({
          display_name: this.broadcast.user.username,
          id: this.broadcast.user.id,
        });
      }
      return results;
    },
  },
  methods: {
    ...mapActions({
      onLoadUsers: `broadcasts/${BROADCASTS_TYPES.LOAD_USERS}`,
    }),
    onWaitChangeUser() {
      if (
        this.searchUser &&
        (this.searchUser.length >= 2 || this.searchUser === "")
      ) {
        this.onLoadUsers(`search=${this.searchUser}`);
      }
    },
    updateBroadcastEvent() {
      this.is_loading = true;
      let data = {
        name: this.broadcast.name,
        actual_links: this.broadcast.actual_links,
        tags: this.$refs.tags.getTagsList(),
      };
      if (this.can_change_user) data.user = this.user ? this.user.id : null;
      updateBroadcast_v2(this.broadcast.id, data)
        .then((response) => {
          if (response.status === requestStatus.success) {
            window.getApp.$emit("UPDATE_BROADCAST_v2_LIST");
            this.isOpen = false;
            this.$toaster.success("Broadcast updated");
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
